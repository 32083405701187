import React from 'react'
import { Link } from 'gatsby'

export default () => {
    return (
        <div className='mx-auto max-w-2xl text-center mt-12'>
            <h1 className='text-3xl font-medium'>Support</h1>
            <p className='max-w-md mx-auto mt-8'>
                We really appreciate your business and would love to sort out any issues/queries you
                have.
            </p>
            <div className='mt-12 mb-6'>
                <a
                    href='mailto:support@mono.fm'
                    className='border border-gray-800 rounded px-10 py-3 text-white text-sm bg-gray-800 shadow hover:shadow-xl mx-2 transition duration-150'
                >
                    Get Support
                </a>
                <Link
                    className='border border-gray-100 rounded px-10 py-3 text-gray-800 text-sm bg-white shadow hover:shadow-xl mx-2 transition duration-150'
                    to='/docs'
                >
                    View Docs
                </Link>
            </div>
            <div className='text-sm text-gray-600'>
                <span>or send an email directly to support@mono.fm</span>
            </div>
        </div>
    )
}
